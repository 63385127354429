const editlinenote = lineid => {
  document.getElementById('linenote-' + lineid).className = 'hidden';
  document.getElementById('note_' + lineid).className = 'visible';
  document.getElementById('editlinenotereftext-' + lineid).className =
    'visible';
  const input = document.getElementById('editlinenoteinput-' + lineid);
  input.readOnly = false;
  input.focus();
  return false;
}

const uneditlinenote = (input, lineid, evt) => {
  if (evt.keyCode == 27) {
    // Escape key was pressed
    input.value = input.defaultValue;
    input.readOnly = true;
    document.getElementById('linenote-' + lineid).className = 'visible';
    document.getElementById('note_' + lineid).className = 'hidden';
    document.getElementById('editlinenotereftext-' + lineid).className =
      'hidden';
    document.getElementById('linenotetext-' + lineid).focus();
    return false;
  } else {
    // Do nothing, let the keypress pass through.
    return true;
  }
}

export { editlinenote, uneditlinenote };
