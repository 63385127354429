/* global nx */
import {
  addClassName,
  evalScript,
  getElementByID,
  parseAjaxText,
  removeClassName
} from '../ute/utils';
import { wrapK2Message } from '../ute/k2message';
import swapOrderItems from './swapOrderItems';
/********************************
 * helpers
 *******************************/
let openDialog;
const dialogHandle = 'a.alt_popup';
const dialogTitle = '';
const dialogWidth = 850;

const onComplete = (dialog, itemID) => {
  let reason, err_block;
  openDialog = dialog;
  dialog.setDimensions();
  reason = getElementByID('swap_reason_' + itemID).value;
  err_block = getElementByID('suggestion_messages');
  getElementByID('swap_accept_' + itemID).addEventListener('click', e => {
    let quantity = getElementByID('swap_quantity_' + itemID).value;
    const qtyField = getElementByID('swap_quantity_' + itemID);
    err_block.innerHTML = '';
    quantity = parseInt(quantity, 10);
    removeClassName(qtyField, 'component_error');
    dialog.setDimensions();
    if (quantity == null || isNaN(quantity) || quantity < 1) {
      addClassName(qtyField, 'component_error');
      err_block.innerHTML += wrapK2Message('Please enter a quantity', 'error');
      e.preventDefault();
      e.stopPropagation();
      dialog.setDimensions();
      return false;
    }
    const callback = nx?.cart?.refreshCart;
    swapOrderItems(
      itemID,
      'accept',
      reason,
      quantity,
      callback,
      null,
      {
        feature: getElementByID(`swap_feature_${itemID}`),
        feature_ident: getElementByID(`swap_feature_ident_${itemID}`)
      }
    );
    document.body.scrollTo();
    dialog.close();
    e.preventDefault();
    e.stopPropagation();
    return false;
  });
  getElementByID('swap_reject_' + itemID).addEventListener('click', e => {
    dialog.close();
    e.preventDefault();
    e.stopPropagation();
    nx.ute.doAdobeEvent('event132', 'easy alternative rejected');
    return false;
  });

  // load etdp for Z001 - faster delivery alternative.
  if (reason === 'Z001') {
    // etd-profiled users already have etdp loaded, update etd divs.
    if (etdp) {
      etdp.getAllTargets('#alt_container');
      etdp.updateAllTargets();
    }
    // non-etd-profiled users do not have etdp loaded yet,
    // load etd which automaticaly updates etd divs.
    else {
      window.etdSettings = window.etdSettings || {};
      window.etdSettings.initialTarget = '#alt_container';
      window.etdSettings.doNotPersist = true;
      const tag = document.createElement('script');
      tag.type = 'text/javascript';
      tag.async = true;
      tag.src = '/js/built_etd.legacy.js';
      const loc = document.getElementsByTagName('script')[0];
      loc.parentNode.insertBefore(tag, loc);
    }
  }
};

const content =
  '<div>' +
  '<div style="padding: 10px;" id="alt_container">' +
  '<p style="text-align: center;"><img src="/media/images/new/ajax-loader.gif" /></p>' +
  '<p style="text-align: center;">please wait...</p>' +
  '</div>';
/********************************
 * dialogs()
 *******************************/
export const dialogs = () => {
  Dialogs.load();
  let ev = {};
  const afterClick = e => {
    ev = e;
  };

  new Dialog({
    handle: dialogHandle,
    title: dialogTitle,
    width: dialogWidth,
    afterClick: afterClick,
    afterOpen: d => {
      nx.ute.doAdobeEvent('event130', 'easy alternative dialog');
      let el = ev.target.closest('a');
      fetch(`/main-ajax-suggest?orderitemid=${el.id}`)
        .then(parseAjaxText)
        .then(text => {
          const altContainer = getElementByID('alt_container');
          altContainer.innerHTML = text;
          onComplete(d, el.id);
          evalScript(altContainer);
          openDialog = d;
          d.setDimensions();
        });
    },
    content
  });
};
/********************************
 * dialogs()
 *******************************/
export class FormChecker {
  constructor(formId, messageTarget) {
    this.applyChecks = this.applyChecks.bind(this);

    this.checks = [];
    this.form = getElementByID(formId);
    this.form.addEventListener('submit', this.applyChecks);
    this.messageTarget = messageTarget;
  }

  add_check(fieldID, checkFn, msgText) {
    this.checks.push({
      field: getElementByID(fieldID),
      checkFn,
      msgText
    });
  }
  applyChecks(ev) {
    let t = getElementByID(this.messageTarget);
    let ok = true;
    const seen = {};

    t.innerHTML = '';
    this.checks.forEach(({ field, checkFn, msgText }) => {
      const { id } = field;
      if (!seen[id]) {
        removeClassName(field, 'component_error');
        seen[id] = true;
      }
      if (!checkFn(field.value)) {
        t.innerHTML += wrapK2Message(msgText, 'error');
        addClassName(field, 'component_error');
        ok = false;
      }
    });
    if (!ok) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    /* If anything has a dialog open */
    if (openDialog) {
      openDialog.setDimensions();
    }
  }
}
