import { getElementByID, px } from '../ute/utils';

const visible = 'visible';
const hidden = 'hidden';
const tp = '_tooltip';
const rtp = '_rtooltip';
const getTooltipID = id => `${id}${tp}`;
const getRTooltipID = id => `${id}${rtp}`;

const setPosition = el => {
  const { id } = el;
  const toolTipDiv = getElementByID(getTooltipID(id));
  const rToolTipDiv = getElementByID(getRTooltipID(id));
  const tooltip = toolTipDiv || rToolTipDiv;
  const { offsetWidth: tWidth } = tooltip;
  const { offsetWidth: dWidth, offsetHeight: dHeight } = el;
  const l =
    el.parentElement.parentElement.parentElement.querySelector(
      'label.standard'
    );

  if (toolTipDiv === null) {
    if (typeof l !== 'undefined' && l.length > 0) {
      tooltip.style.left = px(dWidth + 128 + 28);
    } else {
      tooltip.style.left = px(dWidth + 28);
    }
    tooltip.style.top = px(dHeight / 2 - 15);
  } else {
    if (typeof l !== 'undefined' && l.length > 0) {
      tooltip.style.left = px(dWidth / 2 - tWidth / 2 + 128);
    } else {
      tooltip.style.left = px(dWidth / 2 - tWidth / 2);
    }
    tooltip.style.bottom = px(dHeight + 3);
  }
};

const manageTip = (el, visibility) => {
  const id = el.id;
  const tooltip =
    getElementByID(getTooltipID(id)) || getElementByID(getRTooltipID(id));

  setPosition(el);
  tooltip.style.visibility = visibility;
};

const manageFocus =
  visibility =>
  ({ target }) => {
    manageTip(target, visibility);
  };

const initializeElements = () => {
  const tipFields = document.querySelectorAll(
    'input.do_tooltip, textarea.do_tooltip'
  );

  tipFields.forEach(field => {
    const { id } = field;
    const toolTipDiv = getElementByID(getTooltipID(id));
    const rToolTipDiv = getElementByID(getRTooltipID(id));

    const div = toolTipDiv || rToolTipDiv;

    if (div === null) {
      return;
    }

    field.addEventListener('focus', manageFocus(visible));
    field.addEventListener('blur', manageFocus(hidden));
  });
};
// TODO - Manual test - @Kyle
class Tooltip {
  constructor() {
    initializeElements();
  }
}

export default Tooltip;
