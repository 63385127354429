export const setCookie = (cname, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  const expires = '; expires=' + date.toGMTString();
  document.cookie = cname + '=' + value + expires + '; path=/';
};

export const getCookie = cname => {
  var re = new RegExp(cname + '=([^;]+)');
  var value = re.exec(document.cookie);
  return value != null ? unescape(value[1]) : null;
}
