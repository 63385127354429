/* global $j */

function decrease_qty(evt) {
    const qty_field = this.nextSibling;
    const curr_val = parseInt(qty_field.value,10) || 0;
    evt.stopPropagation();
    evt.preventDefault();
    qty_field.value = curr_val <= 1 ? 1 : curr_val - 1;
}

function increase_qty(evt) {
    const qty_field = this.previousSibling;
    const curr_val = parseInt(qty_field.value,10) || 0;
    evt.stopPropagation();
    evt.preventDefault();
    qty_field.value = curr_val + 1;
}

function add_quantity_controls() {
    let nums;

    nums = $j('.qty_text_large')
            .filter(function() {
                if (this.name.match(/quantity/)) {
                    return true;
                } else {
                    return false;
                }
            });

    if (nums.length >= 1) {
        nums.each(function() {
            const node = this;
            const wrapper = document.createElement('span');
            const minus = document.createElement('button');
            const plus = document.createElement('button');
            minus.title = "Decrease quantity";
            minus.type = "button";
            plus.title = "Increase quantity";
            plus.type = "button";

            if( ! node.closest('.qty-wrap') ) {
                node.addEventListener('focus', function(e){
                    node.select();
                });

                node.parentNode.insertBefore(wrapper,node);
                wrapper.appendChild(node);
                wrapper.classList.add('qty-wrap');

                node.parentNode.insertBefore(minus,node);
                node.insertAdjacentElement('afterend',plus);

                minus.className = 'fa fa-minus decreaser enlighten ensmall transition-fast-ease';
                minus.addEventListener('click', decrease_qty);
                plus.className = 'fa fa-plus increaser enlighten ensmall transition-fast-ease';
                plus.addEventListener('click', increase_qty);

                node.classList.add('qty_num_large');
                node.pattern = '[`0-9]*';
                node.setAttribute('inputmode','numeric');
                node.title = '';

                node.addEventListener('keydown', function(evt) {
                    if ( evt.key == 'Enter'){
                        const sbutton = evt.target.form.querySelector('input[type=submit],button[type=submit]');
                        if(sbutton) {
                            sbutton.click();
                            evt.stopPropagation();
                            evt.preventDefault();
                        }
                    }
                });
            }
        });
    }
}


export {add_quantity_controls, decrease_qty, increase_qty };
