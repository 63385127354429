export const enter_to_submit = () => {
  document.querySelectorAll('.qty_text').forEach(function (ele) {
    ele.addEventListener('keypress', function (evt) {
      if (evt.keyCode == 13) {
        const add_items = ele.parentNode.querySelectorAll('#add_items')[0];
        if (typeof add_items != 'undefined') {
          add_items.click();
          evt.stopPropagation();
          evt.preventDefault();
        }
      }
    });
  });
}
