// stock-notification checkbox setter

function addNotificationEvent(prodcode) {
  // If prodcode is not input, we will look it up in the 'notification_prodcode' hidden input
  const notification = document.getElementById('stock_notification');
  if (! notification) {
    return;
  }
  if (!prodcode) {
    const prodcode_input = document.getElementById('notification_prodcode');
    if (prodcode_input) {
      prodcode = prodcode_input.value;
    }
    if (! prodcode) {
      return;
    }
  }
  notification.addEventListener('change', function() {
    setNotification(prodcode, this.checked);
  });
}

function setNotification(prodcode, value) {
  const url = `/main-ajax-stocknotify?prodcode=${prodcode}&notification=${value ? 1 : 0}`;
  fetch(url, {
    method: 'POST',
    credentials: 'same-origin'})
    .then(response => {
      if (!response.ok) {
        throw Error('bad response');
      }
      // Adobe Analytics
      var s = s_gi(s_account);
      var ev = (value ? 'event125' : 'event126');
      s.products = `;${prodcode}`;
      s.events = ev;
      s.linkTrackVars = 'events,products';
      s.linkTrackEvents = ev;
      s.tl(true, 'o', `Stock Notification ${value ? 'Enabled' : 'Disabled'}`);
      return;
    })
    .catch(e => {
      throw Error('Failed to set notification');
    });
}


export {addNotificationEvent};
