var resizeOrganiser = function resizeOrganiser() {
  this.initialize.apply(this, arguments);
};

resizeOrganiser.prototype.initialize = function () {
  var obj = this;

  obj.timeout_pointer = false;
  obj.targets = [ ];
  obj.distances = [ ];
  obj.prev_width = window.outerWidth;
  obj.prev_height = window.outerHeight;

  window.addEventListener('resize', function (evt) {
    if (obj.timeout_pointer) {
      window.cancelAnimationFrame(obj.timeout_pointer);
    }

    obj.timeout_pointer = window.requestAnimationFrame(function () {
      obj.monitorResize(obj);

      obj.prev_width = window.outerWidth;
      obj.prev_height = window.outerHeight;
    });
  });
};

resizeOrganiser.prototype.register = function register(target, distance) {
  var obj = this;

  if (obj.distances[distance]) {
    obj.distances[distance].push(obj.targets.length);
  } else {
    obj.distances[distance] = [ obj.targets.length ];
  }

  obj.targets.push(target);
};

resizeOrganiser.prototype.monitorResize = function monitorResize(obj) {
  const width_distance = Math.abs(obj.prev_width - window.outerWidth);
  const height_distance = Math.abs(obj.prev_height - window.outerHeight);

  obj.distances.forEach((objs, min_distance) => {
    if ((min_distance <= width_distance) || (min_distance <= height_distance)) {
      objs.forEach(targ => !!obj.targets[targ].resize && obj.targets[targ].resize());
    }
  });
};

const resizeManager = new resizeOrganiser();

export { resizeManager };
