const minQty = 1;
const maxQty = 999999;

const changeBtnState = el => {
    const decBtn = el.parentNode.querySelector('.itemcard__qty_less');
    const incBtn = el.parentNode.querySelector('.itemcard__qty_more');
    const input = el.parentNode.querySelector('input');

    if(input.value <= minQty) {
        decBtn.disabled = true;
    } else {
        decBtn.disabled = false;
    }

    if(input.value >= maxQty) {
        incBtn.disabled = true;
    } else {
        incBtn.disabled = false;
    }
}
export const increaseQty = btn => {
    const input = btn.parentNode.querySelector('input');
    let v = 1 + parseInt(input.value, 10);
    if(v > maxQty) v = maxQty;
    input.value = v;
    changeBtnState(btn);
};
export const decreaseQty = btn => {
    const input = btn.parentNode.querySelector('input');
    let v = -1 + parseInt(input.value, 10);
    if(v < minQty) v = minQty;
    input.value = v;
    changeBtnState(btn);
};
export const focusItem = (el, focusMe) => {
    if(focusMe) {
        el.parentNode.classList.add('itemcard__qty-ctrl--focused');
        el.select();
    } else {
        el.parentNode.classList.remove('itemcard__qty-ctrl--focused');
    }
};
export const changeItem = btn => {
    const input = btn.parentNode.querySelector('input');
    if(input.value < minQty) input.value = minQty;
    if(input.value > maxQty) input.value = maxQty;
    changeBtnState(btn);
};
