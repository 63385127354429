/* global nx */
import { updateParam } from '../ute/utils';

var typeAheadNew = function typeAheadNew() {
  this.initialize.apply(this, arguments);
};

typeAheadNew.prototype.initialize = function initialize(
  ta_field,
  div_name,
  mode,
  next_field,
  feature_prefix,
  save_on_back
) {
  var obj = this;

  this.noSave = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    && window.nx.ute.readDatalayer(['global', 'streams', 'saf']);

  obj.ta_field = ta_field;
  obj.div_name = div_name;
  obj.mode = mode || 'link';
  obj.next_field = next_field || null;
  obj.feature_prefix = feature_prefix || null;
  obj.save_on_back = save_on_back || null;
  if (this.noSave) obj.save_on_back = null;
  // Pause for browser auto-fill
  obj.initializeElements(); // do it now
  return this;
};

typeAheadNew.prototype.initializeElements = function initializeElements() {
  var ta_field, div_name, obj, div;
  var initialValue = this.save_on_back
    ? decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          '^(?:.*[&\\?]' +
          encodeURIComponent('ta_term').replace(/[\.\+\*]/g, '\\$&') +
          '(?:\\=([^&]*))?)?.*$',
          'i'
        ),
        '$1'
      )
    )
    : '';

  if (document.getElementById(this.ta_field)) {
    ta_field = document.getElementById(this.ta_field);
    div_name = this.div_name;
    obj = this;
    if (
      navigator.userAgent.indexOf('Opera') === -1 &&
      navigator.userAgent.indexOf('compatible') > -1 &&
      navigator.userAgent.indexOf('MSIE') > -1 &&
      parseInt(
        navigator.userAgent.substring(navigator.userAgent.indexOf('MSIE') + 5),
        10
      ) < 7
    ) {
      /* Don't attach event listeners for IE6, this feature will
       * not work. */
      ta_field.attr('autocomplete', true);
      return null;
    }

    this.prev_value = this.terms();
    this.has_results = false;
    this.px = setInterval(obj.ta_load_maybe.bind(obj), 500);
    this.ajax_active = false;
    this.key_select = false;

    div = document.getElementById(div_name);
    if (div) {
      // Apply event listeners
      ta_field.addEventListener('focus', obj.inputFocus.bind(obj));
      ta_field.addEventListener('keydown', obj.hilight_select.bind(obj));
      ta_field.addEventListener('keyup', obj.maybe_spinner.bind(obj));
      if (this.save_on_back)
        ta_field.addEventListener('keyup', obj.store_in_url.bind(obj));
    }

    // Apply event listeners for terms clear button
    if (document.getElementById('terms-clear')) {
      document
        .getElementById('terms-clear')
        .addEventListener('click', function (ev) {
          ev.preventDefault();
          ev.stopPropagation();
          ta_field.value = '';
          ta_field.focus();
        });
    }

    if (initialValue != '') {
      ta_field.value = initialValue;
      ta_field.focus();
    }

    return true;
  }
  return false;
};

typeAheadNew.prototype.terms = function terms() {
  const ta_field = document.getElementById(this.ta_field);
  if (!ta_field) return '';
  let termItems = ta_field.value;
  /* No leading or trailing space */
  termItems = termItems.replace(/(^ +| +$)/g, '');

  /* Normalise inline spaces to singles */
  termItems = termItems.replace(/ +/g, ' ');
  return termItems;
};

typeAheadNew.prototype.showTA = function showTA() {
  var div_name = this.div_name;
  var div = document.getElementById(div_name);

  /* Once typeahead opens, turn off autocomplete */
  document.getElementById(this.ta_field).setAttribute('autocomplete', 'off');

  // add search btn active for new search
  document.getElementById('searchbox_new_btn').classList.add('searchbox_new--search-btn-active');

  div.classList.add('ta-visible');
  this.inShow = true;
};

typeAheadNew.prototype.hideTA = function hideTA() {
  var div_name = this.div_name;
  var div = document.getElementById(div_name);
  var obj = this;
  obj.pendingHide = setTimeout(function () {
    obj.pendingHide = 0;
    // add search btn active for new search
    document.getElementById('searchbox_new_btn').classList.remove('searchbox_new--search-btn-active');
    div.classList.remove('ta-visible');
    obj.inShow = false;
    obj.keepOpen = false;
  }, 300);
};

typeAheadNew.prototype.inputFocus = function inputFocus(ev) {
  if (this.keepOpen) return;
  if (this.mode == 'link') this.ta_load_maybe(null, true);
  if (this.has_results) {
    this.showTA();
  }
};

typeAheadNew.prototype.inputBlur = function inputBlur(ev) {
  // look for newly focused element
  var el = ev.relatedTarget || document.activeElement;
  if (el && document.getElementById(this.div_name).contains(el)) {
    // user is interacting with search box now
    return;
  }
  this.hideTA();
};

typeAheadNew.prototype.hilight_select = function hilight_select(ev) {
  var hilight_index, hilight_el, a, ta_div;
  ta_div = document.getElementById(this.div_name);
  if (ev.key == 'ArrowUp' || ev.key == 'ArrowDown') {
    this.key_select = true; // User is selecting with arrow keys.
    // Check if user is moving next or previous
    var move_next = ev.key == 'ArrowDown';
    // Get all ta_item elements
    var ta_items = ta_div.querySelectorAll('div.ta_item');

    // Find the currently highlighted item
    var current_item = ta_div.querySelector('.ta_item_hilight');

    // If no item is highlighted, highlight the first one
    if (!current_item) {
      hilight_index = 0;
    } else {
      // Find the index of the current item
      var current_index = Array.from(ta_items).indexOf(current_item);

      // Remove the highlight from the current item
      current_item.classList.remove('ta_item_hilight');

      // Calculate the index of the next item to highlight based on the arrow key direction
      hilight_index = move_next ? (current_index + 1) % ta_items.length : (current_index - 1 + ta_items.length) % ta_items.length;
    }

    // Highlight the selected item
    hilight_el = ta_items[hilight_index];
    if (hilight_el) {
      hilight_el.classList.add('ta_item_hilight');

      this.selected_div = hilight_el;
      hilight_el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    ev.stopPropagation();
    ev.preventDefault();
  }

  if (ev.key == 'Enter') {
    if (this.key_select && this.selected_div) {
      a =
        this.selected_div.querySelectorAll('div.ta_item a')[0] ||
        this.selected_div.querySelectorAll('a')[0];
      ev.stopPropagation();
      ev.preventDefault();
      if (window.s) {
        window.s.linkTrackVars = 'prop33';
        window.s.prop33 = 'Select by keyboard';
        window.s.tl(true, 'o', 'Typeahead Select');
      }
      this.do_action(a);
    }
  }
};

typeAheadNew.prototype.do_action = function do_action(elt) {
  if (this.mode == 'link') {
    document.location.href = elt.href;
  } else if (this.mode == 'fillcode') {
    if (this.feature_prefix) {
      document
        .getElementById(this.feature_prefix + '_feature').value = 'product_typeahead';
      document
        .getElementById(this.feature_prefix + '_feature_ident').value = this.terms();
    }
    this.prev_value = elt.getAttribute('data-prodcode');
    const ta_field = document.getElementById(this.ta_field);
    ta_field.value = elt.getAttribute('data-prodcode');
    ta_field.focus();
  }
};

typeAheadNew.prototype.maybe_spinner = function maybe_spinner(ev) {
  var ta_div = document.getElementById(this.div_name);
  var wrapper = ta_div.querySelectorAll('div.ta_wrapper');
  var terms = this.terms();
  var spinner, spinners;

  if (this.prev_value != terms && terms.length > 1 && wrapper.length == 1) {
    spinner = document.createElement('div');
    spinner.classList.add('ajax_spinner');
    if (wrapper[0].firstChild) {
      wrapper[0].insertBefore(spinner, wrapper[0].firstChild);
    } else {
      wrapper[0].appendChild(spinner);
    }
  } else {
    spinners = ta_div.querySelectorAll('div.ajax_spinner');
    spinners.forEach(function (spin) {
      spin.remove();
    });
  }
};

typeAheadNew.prototype.store_in_url = function store_in_url() {
  if (typeof history.replaceState == 'undefined') return false;
  history.replaceState(
    {},
    window.title,
    updateParam(location.href, 'ta_term', this.terms())
  );
  return true;
};

typeAheadNew.prototype.ta_load_maybe = function ta_load_maybe(ps, forceReload) {
  var terms = this.terms();
  if (
    (forceReload || this.prev_value != terms) &&
    !this.ajax_active &&
    (terms.length > 1 || this.mode != 'fillcode')
  ) {
    this.prev_value = terms;
    this.ta_load('');
  } else if (this.inShow) {
    // check if we should hide TA box
    var ta_field = document.getElementById(this.ta_field);
    if (
      document.activeElement != ta_field &&
      !document.getElementById(this.div_name).contains(document.activeElement)
    ) {
      this.hideTA();
    }
  }
};

typeAheadNew.prototype.result_over = function result_over(ev, div) {
  if (this.selected_div != null) {
    this.selected_div.classList.remove('ta_item_hilight');
  }
  this.selected_div = div;
  div.classList.add('ta_item_hilight');
  this.key_select = false;
};

typeAheadNew.prototype.result_click = function result_click(ev, div) {
  // If event target is input or button ignore the click event
  if (ev.target.tagName === 'INPUT' || ev.target.tagName === 'BUTTON') {
    return;
  }

  var a = div.querySelectorAll('a');
  if (window.s) {
    window.s.linkTrackVars = 'prop33';
    window.s.prop33 = 'Select by mouse';
    window.s.tl(true, 'o', 'Typeahead Select');
  }

  this.do_action(a[0]);
};

typeAheadNew.prototype.ta_load = function ta_load(ev) {
  var div = document.getElementById(this.div_name);
  var obj = this;
  this.ajax_active = true;

  var url = '/_typeahead/';
  var show_pc = 0;
  if (this.mode == 'fillcode') {
    url = url + 'product';
    show_pc = 1;
  }

  var obj_term = obj.terms();

  // For searchnew show/hide clear button based on term length
  if (obj_term.length > 0) {
    document.getElementById('searchbox_new_clear').style.display = 'flex';
  } else {
    document.getElementById('searchbox_new_clear').style.display = 'none';
  }

  fetch(url + '?term=' + obj_term + '&show_prodcode=' + show_pc, {
    method: 'GET'
  })
    .then(response => response.text())
    .then(data => {
      document.getElementById(obj.div_name).innerHTML = data;
      var sub_divs = div.querySelectorAll('div.ta_wrapper');
      var max_height = 0;

      // Recent Searches
      var recent_terms = div.querySelectorAll('div.ta_recent_my_terms');
      // Popular Searches
      var pop_terms = div.querySelectorAll('div.ta_pop_terms');
      // Search in Category
      var item_rollups = div.querySelectorAll('div.ta_item_rollups');
      // Suggested Searches
      var top_terms = div.querySelectorAll('div.ta_top_terms');
      // Recommended Items
      var rec_items = div.querySelectorAll('div.ta_recommended_items');
      var non_product_suggestions = div.querySelectorAll('div.non_product_suggestions');
      var result_divs, labels;

      if (
        recent_terms.length +
        pop_terms.length +
        item_rollups.length +
        top_terms.length +
        rec_items.length +
        non_product_suggestions.length ==
        0 ||
        (obj.mode == 'fillcode' && rec_items.length == 0)
      ) {
        obj.has_results = false;
        obj.ajax_active = false;
        if (window.s && Math.random() < 0.1) {
          /* Sample only 1 in 10 */
          window.s.linkTrackVars = 'prop32';
          window.s.prop32 = 'No Results';
          window.s.tl(true, 'o', 'Typeahead');
        }

        obj.keepOpen = false;
        obj.showTA();
        return true;
      }

      obj.has_results = true;
      obj.keepOpen = false;
      obj.showTA();

      // load ajax add to cart event handlers
      if (
        typeof nxDatalayer == 'object' &&
        nxDatalayer.global.profiles.TaFastAdd
      )
        new window.nx.addToCart.AddToCartForm(
          'div.ta_recommended_items form.add-to-cart'
        ); // eslint-disable-line no-use-before-define

      // load my search history
      if (recent_terms.length > 0 && recent_terms[0].classList.contains('ta_recent_my_terms')) {
        obj.load_term_hist(recent_terms[0], item_rollups);
      }

      result_divs = div.querySelectorAll('div.ta_item');
      result_divs.forEach(function (e) {
        e.addEventListener('click', function (evt) {
          obj.result_click(evt, e);
        });
      });

      // For searchnew ta_recommendation add click event handler to plus and minus
      var plus = div.querySelectorAll('button.ta_rec_item__rdiv_plus');
      var minus = div.querySelectorAll('button.ta_rec_item__rdiv_minus');
      plus.forEach(function (e) {
        e.addEventListener('click', function (evt) {
          obj.change_item_qty(evt, 'plus');
        });
      });
      minus.forEach(function (e) {
        e.addEventListener('click', function (evt) {
          obj.change_item_qty(evt, 'minus');
        });
      });


      if (window.s) {
        window.s.linkTrackVars = 'prop32';
        if (non_product_suggestions.length > 0 && rec_items.length == 0) {
          window.s.prop32 = 'Non-Product Search';
        } else if (rec_items.length == 0) {
          window.s.prop32 = 'Category Search Only';
        } else if (top_terms.length == 0 && item_rollups.length == 0) {
          window.s.prop32 = 'Products Only';
        } else {
          window.s.prop32 = 'All Functions';
        }
      }

      if (window.s && Math.random() < 0.1)
        /* Sample only 1 in 10 */
        window.s.tl(true, 'o', 'Typeahead');

      non_product_suggestions.forEach(function (e) {
        var dims = e.clientHeight;
        if (dims > max_height + 30) {
          max_height = dims + 30;
        }
      });

      sub_divs.forEach(function (e) {
        var dims = e.clientHeight;
        if (dims > max_height) {
          max_height = dims;
        }
      });

      /* Bulk Add page */
      if (obj.div_name === 'ba_typeahead') {
        var ba_num = 1;
        var ba_parts = /prodcode(\d+)/.exec(obj.ta_field);
        if (parseInt(ba_parts[1], 10) > 10) {
          ba_num = parseInt(ba_parts[1], 10) - 9;
        }
        div.parentNode.style.position = 'relative';
        div.style.top =
          document.getElementById('prodcode1').closest('td').offsetHeight * 2 +
          document.getElementById('prodcode' + ba_num).parentNode.offsetTop +
          'px';
        div.style.left =
          document.getElementById('prodcode1').closest('td').offsetWidth +
          10 +
          document.getElementById('quantity1').closest('td').offsetWidth +
          'px';
      }

      if (
        non_product_suggestions.length > 0 &&
        top_terms.length == 0 &&
        rec_items.length == 0 &&
        item_rollups.length == 0
      ) {
        div
          .querySelectorAll('div.non_product_suggestions')
          .forEach(function (i) {
            i.style.margin = '10px 0 0 10px';
          });
      }

      // Mobile screen set height as 100vh
      if (screen.width < 769) {
        div.style.height = '100vh';
      } else {
        div.style.height = '' + max_height + 'px';
      }

      obj.ajax_active = false;
      obj.selected_div = null;
      obj.maybe_spinner();
      return null;
    });
};

typeAheadNew.prototype.load_term_hist = function load_term_hist(
  recent_terms_div,
  item_rollups
) {
  var obj = this;
  var sh = this.get_search_hist(),
    i;
  var sh_items = '';
  for (i = 0; i < sh.length; i++) {
    var h = sh[i];
    sh_items += obj.get_term_item_tag(h, i);
  }
  if (sh.length) {
    recent_terms_div.innerHTML = sh_items;
    i = 0;
    recent_terms_div.querySelectorAll('div a[href="#"]').forEach(function (e) {
      var hi = sh[i++];
      e.addEventListener('click', function (ev) {
        if (obj.pendingHide) clearTimeout(obj.pendingHide);
        e.parentNode.remove();
        ev.stopPropagation();
        ev.preventDefault();
        if (window.s) {
          window.s.linkTrackVars = 'events,eVar18';
          window.s.linkTrackEvents = 'event67';
          window.s.events = 'event67';
          window.s.eVar18 = hi;
          window.s.tl(true, 'o', 'Delete Term');
        }
        if (obj.delete_term(hi) === 0) {
          // list is empty now
          recent_terms_div.style.display = 'none';
          item_rollups.forEach(function (ir) {
            ir.style.margin = '10px 10px 0 10px';
          });
        }
        // focus the search input again such that TA doesn't hide
        document.getElementById(obj.ta_field).focus();
      });
    });
  } else {
    var div = document.getElementById(this.div_name);
    var recent_searches_div = div.querySelectorAll('div.ta_recent_searches');
    recent_searches_div[0].innerHTML = '';
    recent_terms_div.remove();
  }
};

typeAheadNew.prototype.get_term_item_tag = function get_term_item_tag(h, i) {
  var term_item =
    '<div class="tag_item ta_item">' +
    '<div class="tag_item__term">' +
    '<a href="/main-catalogue-search?Ntt=' + encodeURI(h) +
    '&searchtrigger=typeahead_user_history&ta_selected=' + i + '">' +
    h +
    '</a></div>' +
    '<div class="tag_item__close">' +
    '<div class="tag_item__close_div"></div>' +
    '<div class="tag_item__close_button">' +
    '<a href="#" title="remove"><i class="fa fa-close"></i></a>' +
    '</div></div></div>';
  return term_item;
};

typeAheadNew.prototype.delete_term = function delete_term(term) {
  if (!localStorage || !term) return -1;
  var hist = JSON.parse(localStorage.getItem('nxsh')) || [];

  var new_hist = hist.filter(function (h) {
    return h.term !== term;
  });
  localStorage.setItem('nxsh', JSON.stringify(new_hist));
  return new_hist.length;
};

typeAheadNew.prototype.get_search_hist = function get_search_hist() {
  if (!localStorage) return [];
  var hist = JSON.parse(localStorage.getItem('nxsh')) || [];
  let ret;
  ret = hist.map(o => o.term);
  ret.splice(8);
  return ret;
};

// change Item Qty based on the type (plus or minus)
typeAheadNew.prototype.change_item_qty = function change_item_qty(evt, type) {
  var obj = this;
  if (obj.pendingHide) clearTimeout(obj.pendingHide);
  evt.stopPropagation();
  evt.preventDefault();

  // Get parent element of the plus or minus button
  const parent_elem = evt.target.closest('div.ta_rec_item__rdiv');
  var qtyinput = parent_elem.querySelector('input.ta_rec_item_rdiv_qty--input');
  var value = parseInt(qtyinput.value, 10) || 1;
  if (type === 'plus' && value > 0) {
    value = value + 1;
  } else if (type === 'minus' && value > 1) {
    value = value - 1;
  }
  if (isNaN(value) && value < 0) {
    value = 1;
  }
  // Update the qty value
  qtyinput.value = value;
  if (value > 1) {
    parent_elem.querySelector('button.ta_rec_item__rdiv_minus').removeAttribute('disabled');
  } else {
    parent_elem.querySelector('button.ta_rec_item__rdiv_minus').setAttribute('disabled', 'disabled');
  }
  // focus the search input again such that TA doesn't hide
  obj.keepOpen = true;
  document.getElementById(obj.ta_field).focus();
};
export default typeAheadNew;
