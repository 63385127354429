/* globals nx */
import {getCardWidth} from '../ute/utils.js';

function insertMerchRows(){
    try {
        const itemCards = nx.ute.readDatalayer(['global', 'streams', 'itemcard']);
        const cardsSelector = itemCards
            ? '.flexgrid .itemcard_container'
            : '.search_results_container .clearfix_grid';
        const cardSelector = itemCards
            ? '.itemcard'
            : '.griditem';
        var resultsDiv = document.querySelector(cardsSelector);
        if(!resultsDiv){return false;}
        var cardBanners = document.querySelectorAll('#inline-merch .card_banner');
        var cards = resultsDiv.querySelectorAll(cardSelector);
        if(cardBanners.length > 0) {
            var cardBanner = cardBanners[0];
            var pos = cardBanner.getAttribute('data-position');
            pos = isNaN(pos) || pos + 0 == 0 ? 5 : pos;
            if(cards.length > pos){
                var nthCard = cards[pos - 1];
                resultsDiv.insertBefore(cardBanner, nthCard);
                cards = resultsDiv.querySelectorAll(`${cardSelector}, .card_banner`);
            }
        }
        var merchRows = document.querySelectorAll('#inline-merch .merch-row');
        if(merchRows.length == 0) { return false; }
        var cardWidth = getCardWidth(cards[0]);
        var numPerRow = Math.floor(resultsDiv.clientWidth / cardWidth);
        var merchRowNum = 0;
        var insertEvery;
        var insertAt = insertEvery = numPerRow * 2;
        // insert one endeca row every 2 regular rows
        while(merchRowNum < merchRows.length && insertAt < cards.length) {
            const row = merchRows[merchRowNum];
            if(itemCards) {
                // grid-column: -1 / 1 will span from the last column line to the first column line, 
                // thus covering all 3/4/5/6 columns based on the number of columns
                row.style.gridColumn = "-1 / 1";
            }
            resultsDiv.insertBefore(row, cards[insertAt]);
            insertAt += insertEvery;
            merchRowNum++;
        }
    }
    catch(err){console.log(err);}// eslint-disable-line no-console
    return true;
}

export {insertMerchRows};
