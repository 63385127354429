import { get } from 'lodash';
/********************************
 * helpers
 *******************************/
const tracking = 'tracking';
const cart = 'cart';
const inCartKey = 'in_cart';
const stripClassName = '.promo_container';
const excludeAttribute = 'data-exclude-incart';
const itemKey = 'data-itemkey';
const containerClassName = 'ps_ic_container';

const getContent = count =>
  `<div class="promo_strip ps_ic">${count} in cart</div>`;

const updateStrip = inCart => strip => {
  const exclude = strip.getAttribute(excludeAttribute);
  const key = strip.getAttribute(itemKey);
  const count = inCart[key];

  if (exclude || !count) {
    if(strip.classList.contains(containerClassName)) {
      strip.classList.remove(containerClassName);
      strip.innerHTML = '';
    }
    return;
  }

  strip.innerHTML = getContent(count);
  strip.classList.add(containerClassName);
};
/********************************
 * updateInCartStrip()
 *******************************/
const updateInCartStrip = () => {
  if (typeof nxDatalayer === 'undefined') {
    return;
  }
  const inCart = get(nxDatalayer, [tracking, cart, inCartKey]);
  const strips = document.querySelectorAll(stripClassName);

  if (inCart === undefined) {
    return;
  }

  strips.forEach(updateStrip(inCart));
};

export default updateInCartStrip;
/********************************
 * export for testing
 *******************************/
export {
  tracking,
  cart,
  inCartKey,
  stripClassName,
  excludeAttribute,
  itemKey,
  containerClassName,
  getContent,
  updateStrip
};
