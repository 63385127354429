export default function showChat(show) {
  if(typeof Genesys !== "undefined") {
    const chat = document.querySelector('.genesys-app');
    if (chat) { chat.style.visibility = show ? 'visible' : 'hidden'; }
  } else {
    //salesforce chat
    // the recommended methods don't work once a chat session is established :-(
    // embeddedservice_bootstrap.utilAPI.hideChatButton();
    // embeddedservice_bootstrap.utilAPI.showChatButton();
    // chat session exists
    let elem = document.getElementById('embeddedMessagingFrame');
    if (elem) elem.style.display = show ? 'block' : 'none';
    // no chat session exists
    elem = document.getElementById('embedded-messaging');
    if (elem) elem.style.display = show ? 'block' : 'none';
  }
}

