let login_lightbox_cache = '';

// either fire off the ajax request, or simply display the lightbox
function login_lightbox() {
  if (login_lightbox_cache == '') {
    Dialogs.load();
    let url = "https://" + window.location.host + "/main-my-login";
    url += '?lightbox=1&login_target=' + escape("" + window.location);
    fetch(url, {credentials: 'same-origin'})
        .then(response => response.text())
        .then(responseText => {
            login_lightbox_cache=responseText;
            login_lightbox_dialog();
        });
  } else {
      login_lightbox_dialog();
  }
}

// simply display the lightbox, with the contents of login_lightbox_cache var
function login_lightbox_dialog() {
    const login_dialog = new Dialog({
        title: 'Login',
        autoOpen: true,
        content: login_lightbox_cache,
        innerScrollOnlyY: true
    });

    login_dialog.setDimensions();
    login_dialog.hideTitleBar();
    if(window.nx.login) {
        window.nx.login.login_dialog = login_dialog;
    }
}

export {login_lightbox};
