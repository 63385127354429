import { addClassName, removeClassName, hasClassName } from '../ute/utils';

export const toggleFACheckbox = ele => {
  // Consider both standand and "Font Awesome" checkbox
  const chk_box = ele.querySelectorAll('input')[0];
  const fa_chk_box = ele.querySelectorAll('i')[0];

  // Uncheck checkboxes
  if (chk_box && chk_box.checked) {
    removeClassName(ele, 'checked');
    chk_box.checked = false;
    if (hasClassName(fa_chk_box, 'fa_25')) {
      removeClassName(fa_chk_box, 'fa-check-square');
      addClassName(fa_chk_box, 'fa-square-o');
    } else {
      removeClassName(fa_chk_box, 'fa-check-square-o');
      addClassName(fa_chk_box, 'fa-square-o');
    }
  }
  // Check checkboxes
  else {
    chk_box.checked = true;
    addClassName(ele, 'checked');
    if (hasClassName(fa_chk_box, 'fa_25')) {
      removeClassName(fa_chk_box, 'fa-square-o');
      addClassName(fa_chk_box, 'fa-check-square');
    } else {
      removeClassName(fa_chk_box, 'fa-square-o');
      addClassName(fa_chk_box, 'fa-check-square-o');
    }
  }
}
