const newsflash_reloadmessages = () => {
  /* exit cleanly if (somehow) there is no message_box */
  if (!document.getElementById('message_box')) {
    return;
  }
  fetch('/main-ajax-welcomenews', {
    method: 'GET'
  }).then(response => response.text())
    .then(data => {
      document.getElementById('message_box').innerHTML = "";
    });
}
const newsflash_lightbox = newsid => {
  Dialogs.load();
  window.newsDialog = new Dialog({
    title: 'News',
    autoOpen: true,
    width: 700,
    ajax: function () {
      var url = '/main-ajax-newsflash/';
      if (newsid) {
        url += '?newsid=' + newsid;
      }
      return {
        url: url,
        options: {
          onComplete: function () {
            window.nx.open_lightbox.setDimensions();
          }
        }
      };
    },
    afterOpen: function (d) {
      window.nx.open_lightbox = d;
    },
    afterClose: function () {
      newsflash_reloadmessages();
      window.nx.dialogs.nextDialog();
    }
  });
}
const newsflash_read = (post_id, newsid, read) => {
  let formData = new FormData();
  formData.append('post_id', post_id);
  formData.append('newsid', newsid);
  formData.append('read', read);

  fetch('/main-ajax-readnews', {
    method: 'POST',
    header: {
      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    body: formData
  }).then(function (res) { });
}

export { newsflash_lightbox, newsflash_reloadmessages, newsflash_read };
