/********************************
 * helpers
 *******************************/
const noLimitSubmitClassName = 'no_limit_submit';
const delayInterval = 15000; /* In msec */
let submit_times = {};

const limitOnName = targetName => {
  const timeNow = new Date().getTime();
  const lastSubmit = submit_times[targetName];

  submit_times[targetName] = timeNow;

  if (lastSubmit && lastSubmit > timeNow - delayInterval) {
    return false;
  }

  return true;
};

const limitSubmit = ({ name: formName }) => limitOnName(formName);

const attachFormActions = targetForm => {
  targetForm.onsubmit = () => limitSubmit(targetForm);
};
/********************************
 * export
 *******************************/
export const limitSubmitAttachActions = () => {
  const forms = Array.from(window.document.forms);

  forms.forEach(form => {
    const { className = '' } = form;

    if (className.indexOf(noLimitSubmitClassName) < 0) {
      attachFormActions(form);
    }
  });
};
export const limitSubmitReset = () => {
  /* This flushes the existing recorded submit times */
  submit_times = {};
};
