import { evalScript, parseAjaxText } from '../ute/utils';
import CartItems from './CartItems';
/********************************
 * helpers
 *******************************/
const dashboardID = 'dashboard';
const dashboardDisabledID = 'dashboard_disabled';
const dashboardContainerID = 'dashboard_container';
const imgSrc = '/media/images/new/ajax-mini.gif';
const ajaxURL = '/main-ajax-dashboard';
const style = 'margin-top: 12px;';
const spinner = `<div dr="test" id=${dashboardID}><img style=${style} src=${imgSrc} /></div>`;
const updateDom = el => text => {
  el.innerHTML = text;
  evalScript(el);
  new CartItems(2);
};
/********************************
 * updateCart()
 *******************************/
const updateCart = () => {
  // Replace current dashboard with spinner icon
  const dashboard =
    document.getElementById(dashboardID) ||
    document.getElementById(dashboardDisabledID);
  const dashboard_interstitial = document.getElementById('dashboard_interstitial');
  if (dashboard) {
    dashboard.outerHTML = spinner;
    // Update dashboard to original content
    const el = document.getElementById(dashboardContainerID);
    fetch(ajaxURL).then(parseAjaxText).then(updateDom(el));
  } else if (dashboard_interstitial) {
    fetch(ajaxURL).then(parseAjaxText).then(updateDom(dashboard_interstitial));
  }
};

export default updateCart;
/********************************
 * export for testing
 *******************************/
export {
  dashboardID,
  dashboardDisabledID,
  dashboardContainerID,
  imgSrc,
  ajaxURL,
  style,
  spinner,
  updateDom
};
