export function wrapK2Message (contents='', types='success', otherClasses='') {
  let msg_classes = 'inline_message';
  let icon_classes = 'inline_message__icon';
  types.split(' ').forEach(cl => {
    msg_classes += ` inline_message-${cl}`;
    icon_classes += ` inline_message__icon-${cl}`;
  });
  return `<div class="${msg_classes} ${otherClasses}">
    <div class="${icon_classes}"></div>
    <div class="inline_message__content">
      ${contents}
    </div>
  </div>`;
}
