//display ETD warning if cookie postcode is different from selected address
export const displayEtdWarningOnPostcodeChange = () => {
  let selectedPostcode = '';
  const otda_active = document.getElementById('otda_active');
  const postcode = document.getElementById('postcode');
  if (otda_active && otda_active.value == 0) {
    //has odta option, but it's not checked
    selectedPostcode =
      window.addressPostcodes[document.querySelectorAll('input[name=del_addr]:checked')[0].id];
  } else if (postcode) {
    // has a postcode field, and not using selected addresses
    selectedPostcode = postcode.value;
  } else {
    // selected address
    selectedPostcode =
      window.addressPostcodes[document.querySelectorAll('input[name=del_addr]:checked')[0].id];
  }

  document.querySelectorAll('.delivery-info-warning').forEach(function (div) {
    if (selectedPostcode == window.nxDatalayer.global.postcode) {
      div.style.display = 'none';
    } else {
      div.style.display = 'block';
    }
  });
}
