
const Swipable = function Swipable() {
    this.initialize.apply(this, arguments)
}
Swipable.prototype = {
    initialize: function initialize(swipeLeft,swipeRight,reset,animate,target,sensitivity) {
        if(!target.addEventListener){return false;}
        this.target = target;
        this.swipeLeft = swipeLeft;
        this.swipeRight = swipeRight;
        this.sensitivity = sensitivity || 30;
        this.reset = reset;
        this.animate = animate;
        this.target.addEventListener('click', this.click.bind(this), true); //we want to capture, not bubble
        this.target.addEventListener('mousedown',this.mouseDown.bind(this));
        this.target.addEventListener('touchstart',this.mouseDown.bind(this));
        this.mouseup = this.mouseUpFunc.bind(this);
        this.mousemove = this.mouseMoveFunc.bind(this);
        return true;
    },
    mouseDown: function mouseDown(evt) {
        this.leftStart = this.getX(evt);
        document.addEventListener('mouseup', this.mouseup);
        document.addEventListener('mousemove', this.mousemove);
        document.addEventListener('touchend', this.mouseup);
        document.addEventListener('touchmove', this.mousemove);
        evt.stopPropagation();
    },
    click: function click (evt) {
        var ex = this.getX(evt);
        if (ex != 0 && (Math.abs(this.leftStart - ex) > 3)){
            evt.preventDefault();
            evt.stopPropagation();
        }
    },
    mouseUpFunc: function mouseUp(evt) {
        // remove mouseMove and mouseUp
        document.removeEventListener('mouseup', this.mouseup);
        document.removeEventListener('mousemove', this.mousemove);
        document.removeEventListener('touchend', this.mouseup);
        document.removeEventListener('touchmove', this.mousemove);
        // maybe call action
        if(this.leftStart - this.getX(evt) > this.sensitivity ){
            this.swipeLeft(evt,'swipe');
        }else if(this.leftStart - this.getX(evt) < -(this.sensitivity) ){
            this.swipeRight(evt,'swipe');
        }else{
            this.reset();
        }
    },
    mouseMoveFunc: function mouseMove(evt) {
      this.animate(this.leftStart - this.getX(evt));
    },
    getX: function getX(evt) {
        var touch = evt;
        if (evt.type == 'touchstart' || evt.type == 'touchmove' || evt.type == 'touchend') {
            touch = evt.targetTouches[0] || evt.changedTouches[0];
        }
        return touch.pageX;
    }
};

function showSwipeIcon(target, iconType) {
    const icon = document.createElement('div');
    icon.className = 'swipe_icon ' + iconType;
    icon.style.top = ((target.clientHeight - 34) / 2) + 'px';
    icon.style.marginLeft = ((target.clientWidth - 34) / 2) + 'px';
    target.insertBefore(icon, null);
}

export {Swipable, showSwipeIcon};
