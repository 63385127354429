/* globals toastr, nx */

export const showOrderToast = opts => {
  if (localStorage.getItem('hideOrderToasts') === 'true') {return;}
  if(opts.type) {
    const desc = `orders toast: ${opts.type}`;
    nx.ute.doAdobeWithVars({eVar89: desc, events: 'event128'},desc);
  }
  const timeOut = opts.timeOut || '5000';
  toastr.options = toastr.options = {
    "closeButton": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "showDuration": "0",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  }
  const title = opts.title || '';
  const titleHTML = `<div class="toast-title-fixed">${title}</div>`;
  const contentsHTML = `<div class="toast-content-below">
    <label class="toast-content-label"><input type="checkbox"
      onclick="nx.ute.doAdobeWithVars({eVar89: 'orders toast: hide toasts'},'orders toast: hide toasts');localStorage.setItem('hideOrderToasts', 'true');">
    &nbsp;Do not show this message again</label>
    <a href="/main-orders-console" class="blue_underline_link"
      onclick="nx.ute.doAdobeWithVars({eVar89: 'orders toast: manage link'},'orders toast: manage link');">Manage Saved Orders</a>
    </div>`;
  toastr['info'](contentsHTML, titleHTML);
  return;
}

const dialogCard = (title, order) => {
  let images = order.imgs.map(url => `<img src="${url}" class="merge-card__image" alt="product" />`);
  if (order.rowCount > 4) {
    images = images.slice(0, 4);
    images.push(`<span class="merge-card__image merge-card__more" ></span>`);
  }
  let priceSection = '';
  if (order.value !== '') {
    priceSection = `
      <div class="merge-card__sub">
        Subtotal
      </div>
      <div class="merge-card__price">
        ${order.value}
      </div>
      <div class="merge-card__gst">
        ${order.gst}
      </div>`;
  }
  return `
  <div class="merge-card">
    <div class="merge-card__top">
      <div class="cart_summary">
        <a class="cart_image"></a>
        <div class="merge-card__cart">
          ${order.rowCount}
        </div>
      </div>
      <div class="merge-card__title">
        ${title}
      </div>
    </div>
    <div class="merge-card__bottom">
      <div class="merge-card__left">
        <div class="merge-card__date">
          ${order.datetime}
        </div>
        <div class="merge-card__images">
          ${images.join('')}
        </div>
      </div>
      <div class="merge-card__right">
        ${priceSection}
      </div>
    </div>
  </div>
  `;
};

export const mergeOrderDialog = opts => {
  const title = 'Your cart from your previous session has been saved automatically as a Saved Order';
/* expects something like this
  opts = {
    canMerge: '1',
    previous: {
      rowCount: 12,
      imgs: ['https://static-uat.winc.com.au/aus/thumbs/87215298.png','https://static-uat.winc.com.au/aus/thumbs/87215298.png','https://static-uat.winc.com.au/aus/thumbs/87215298.png'], // max 4
      value: '$123.45',
      gst: 'inc gst',
      datetime: 'Wednesday, 21 Sep',
      orderid: 'NET12341234',
    },
    current: {
      rowCount: 12,
      imgs: ['https://static-uat.winc.com.au/aus/thumbs/87215298.png','https://static-uat.winc.com.au/aus/thumbs/87215298.png','https://static-uat.winc.com.au/aus/thumbs/87215298.png'], // max 4
      value: '$123.45',
      gst: 'inc gst',
      datetime: 'Wednesday, 21 Sep',
      orderid: 'NET12341233',
    }
  }
*/
  const mergeButton = `
    <button type="button" class="wc-button wc-button--secondary"
    onclick="nx.ute.doAdobeWithVars({eVar89: 'merge orders: merge'},'merge orders: merge');nx.orders.switchOrders('${opts.previous.orderid}', 'merge')">
    <span class="label">Merge Carts</span>
    </button>`;
  const prevButton = `
    <button type="button" class="wc-button wc-button--secondary"
    onclick="nx.ute.doAdobeWithVars({eVar89: 'merge orders: previous'},'merge orders: previous');nx.orders.switchOrders('${opts.previous.orderid}', 'switch');">
    <span class="label">Load Previously Saved Order</span>
    </button>`;
  const currentButton = `
    <button type="button" class="wc-button wc-button--primary"
    onclick="nx.orders.mergeAction='continue';window.ordersDialog.close()">
    <span class="label">Continue with current cart</span>
    </button>`;
  const content = `  
  <div class="merge-dlg">
    <div class="merge-head">
      Your cart from your previous session has been saved automatically as a Saved Order
    </div>
    <div class="merge-grey">
      <div class="merge-cards">
        ${dialogCard('Previously Saved Order', opts.previous)}
        ${dialogCard('Current Cart', opts.current)}
      </div>
      <div class="merge-buttons">
        ${prevButton}
        ${opts.canMerge ? mergeButton : ''}
        ${currentButton}
      </div>
    </div>
    <div class="merge-foot">
      Your previously saved carts can still be found as&nbsp; 
      <a href="/main-orders-console" class="blue_underline_link">Saved Orders</a>
    </div>
  </div>
  `;
  nx.ute.doAdobeWithVars({eVar89: 'merge orders: prompt', events: 'event129'},'merge orders: prompt');
  Dialogs.load();
  window.ordersDialog = new Dialog({
    title: '',
    content: content,
    afterClose: function() {
      const action = nx.orders.mergeAction || 'closed';
      const desc = `merge orders: ${action}`;
      nx.ute.doAdobeWithVars({eVar89: desc}, desc);
      nx.dialogs.nextDialog();
    }
  });
  window.ordersDialog.open();
}

export const queueMergeOrderDialog = opts => {
  window.nx.dialogs.dialogQueue['20'] = [mergeOrderDialog, opts];
};

export const switchOrders = (orderid, action) => {
  let formData = new FormData();
  formData.set('orderid', orderid);
  formData.set('action', action);
  let request = fetch('/main-ajax-switchorder', {
    body: formData,
    headers: {
      'X-Post-ID': nxDatalayer.global.post_id,
      // 'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Accept: 'application/json'
    },
    method: 'POST'
  })
  .then(res => {
    if (res.status == 200) {
      return res.json();
    }
    throw new Error('Network response was not ok.');
  })
  .then(transport => {
    let res = {};
    res.responseJSON = transport;
    if (res.responseJSON !== null) {
      if (nxDatalayer.global.streams.mini_cart) {
        Dialogs.close();
        const dl = res.responseJSON.datalayer;
        nxDatalayer.env.cart_endpoint = dl.env.cart_endpoint;
        nxDatalayer.tracking.cart.in_cart = dl.tracking.cart.in_cart;
        nxDatalayer.tracking.cart.orderid = dl.tracking.cart.orderid;
        // don't need to explicitly call update cart, as it won't
        // have been opened yet and we don't want to update twice.
        nx.addToCart.openMiniCart();
        nx.addToCart.updateInCartStrip();
      } else {
        document.location='/main-orders-viewcurrent';
      }
    } else {
      throw new Error('Unexpected response.');
    }
  })
  .catch(error => {
    alert(error);
  });
};
