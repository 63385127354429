/* global s */
import { stripScripts, stripTags } from '../ute/utils';
/********************************
 * helpers
 *******************************/
const getFilteredHistory = (hist, term) =>
  hist.filter(
    ({ term: historyTerm }, index) => historyTerm !== term && index < 49
  );

const getTermHistoryIndex = (histories, term) =>
  histories.findIndex(({ term: historyTerm }) => historyTerm === term);

const nxSearchKey = 'nxsh';
const appendTerm = term => {
  if (!localStorage || !term) {
    return -1;
  }

  const targetTerm = stripTags(stripScripts(term));
  const savedHistory = JSON.parse(localStorage.getItem(nxSearchKey)) || [];
  const termHistoryIndex = getTermHistoryIndex(savedHistory, targetTerm);
  const foundTermHistory = termHistoryIndex > -1;
  const targetHistory = foundTermHistory
    ? {
        ...savedHistory[termHistoryIndex],
        count: savedHistory[termHistoryIndex].count++
      }
    : { term: targetTerm, count: 1 };

  localStorage.setItem(
    nxSearchKey,
    JSON.stringify([
      targetHistory,
      ...getFilteredHistory(savedHistory, targetTerm)
    ])
  );
  return foundTermHistory ? 2 : 1;
};
/********************************
 * collectSearchHistory()
 *******************************/
const collectSearchHistory = () => {
  if (typeof nxDatalayer == 'object' && nxDatalayer.event.search) {
    const ret = appendTerm(nxDatalayer.event.search.term);
    if (
      s &&
      nxDatalayer.event.search.feature == 'globalsearch' &&
      ret == 1 &&
      Math.random() < 0.01
    ) {
      s.linkTrackVars = 'events,eVar18';
      s.linkTrackEvents = 'event66';
      s.events = 'event66';
      s.eVar18 = nxDatalayer.event.search.term;
      s.tl(true, 'o', 'New Search');
    }
  }
};

export default collectSearchHistory;
