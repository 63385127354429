import { addClassName, removeClassName, hasClassName } from '../ute/utils';

export const chooseEmailSub = ele => {
  // When a user unchecks the font awesome checkbox, that means they want to unsubscribe.
  // Therefore an inverse relationship exists between the fa and input checkboxes.

  // Consider both standand and "Font Awesome" checkbox
  const chk_box = ele.querySelectorAll('input')[0];
  const fa_chk_box = ele.querySelectorAll('i')[0];

  // Checked fa means opt-in, so uncheck underlying input
  if (hasClassName(fa_chk_box, 'fa-square-o')) {
    chk_box.checked = false;
    removeClassName(fa_chk_box, 'fa-square-o');
    addClassName(fa_chk_box, 'fa-check-square');
  }
  // Unchecked fa means opt-out, so check underlying input
  else {
    chk_box.checked = true;
    removeClassName(fa_chk_box, 'fa-check-square');
    addClassName(fa_chk_box, 'fa-square-o');
  }
}
