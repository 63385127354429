import { getElementByID } from '../ute/utils';
// TODO: manual test - @Kyle
const updateOrderTotal = (currency, nItems) => {
  // The first part should be removed because I don't think it's in use any more
  const totals = [...document.querySelectorAll('.order_total_curr')];

  totals.forEach(elt => {
    elt.innerHTML = currency;
  });

  const itemCountMob = getElementByID('item_count_mob');
  if (itemCountMob) {
    itemCountMob.innerHTML = nItems;
    return;
  }

  const cartMob = getElementByID('cart_mob');
  if (cartMob) {
    cartMob.innerHTML += `<div id="item_count_mob">${nItems}</div>`;
  }

  const dashboardInterstitial = getElementByID('dashboard_interstitial');
  if (dashboardInterstitial) {
    dashboardInterstitial.innerHTML = currency;
  }
};

export default updateOrderTotal;
