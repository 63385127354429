/* globals nx, s, */
import {readDatalayer, doAdobeLink} from '../ute/utils';

// reminds the user that they have stuff in their cart
const defaultTimeout = 600000;
const SaveCartReminder = function SaveCartReminder(timeout = defaultTimeout){
    if(!this.reminderActive()) {return false;}
    this.closeAction = 'dismissed';
    this.timer = null;
    this.dlg = null;
    this.cBox = null;
    this.timeout = timeout;
    this.storedTitle = document.title;
    document.addEventListener("mousemove", this.resetTimer.bind(this), false);
    document.addEventListener("mousedown", this.resetTimer.bind(this), false);
    document.addEventListener("keypress", this.resetTimer.bind(this), false);
    document.addEventListener("touchmove", this.resetTimer.bind(this), false);
    this.startTimer();
    return this;
};

SaveCartReminder.prototype.reminderActive = function checkPageAndProfile(){
    const allowedPages = [
        "contact us",
        "current order",
        "welcome",
        "order history",
        "open orders"
    ];
    const allowedSections = [
        "page",
        "products",
        "home delivery",
        "my lists"
    ];
    if(localStorage.getItem('noCartReminder')
        || readDatalayer(['global','profiles','noCartReminder'])
        || readDatalayer(['global','is_b2b'])
        || (readDatalayer(['global','is_generic']) && readDatalayer(['global','authenticated']))){
            return false;
    }
    if(allowedPages.indexOf(readDatalayer(['tracking','title'])) > -1
        || allowedSections.indexOf(readDatalayer(['tracking','section'])) > -1 ) {
            return true;
    }
    return false;
}
SaveCartReminder.prototype.startTimer = function startTimer(){
    this.timer = window.setTimeout(this.remind.bind(this), this.timeout);
}
SaveCartReminder.prototype.resetTimer = function resetTimer(){
    if(this.timer) {window.clearTimeout(this.timer);}
    if(!localStorage.getItem('noCartReminder')) {this.startTimer();}
}
SaveCartReminder.prototype.divContent = function divContent(){
    const elem = document.createElement('div');
    elem.classList.add('cart-reminder__content');
    return elem;
}
SaveCartReminder.prototype.buttonBar = function buttonBar(){
    const elem = document.createElement('div');
    elem.classList.add("cart-reminder__buttons");
    return elem;
}
SaveCartReminder.prototype.dSavedMessage = function dSavedMessage(){
    const elem = document.createElement('div');
    const h2 = document.createElement('h2');
    h2.classList.add("cart-reminder__header");
    h2.innerHTML = 'Your cart has been saved as a Saved Order';
    const p = document.createElement('p');
    p.classList.add("cart-reminder__message");
    p.innerHTML = '<a class="cart-reminder__link"href="/main-orders-console" onclick="doAdobeLink(\'Cart reminder:manage Saved Orders\');">Manage open orders</a>';
    elem.insertBefore(h2, null);
    elem.insertBefore(p, null);
    return elem;
}
SaveCartReminder.prototype.btnOk = function btnOk(){
    const elem = document.createElement('button');
    elem.classList.add("wc-button");
    elem.classList.add("wc-button--primary");
    elem.type = 'button';
    elem.innerHTML = '<span class="label">Continue</span>';
    elem.addEventListener('click', function(){this.dlg.close()}.bind(this));
    return elem;
}
SaveCartReminder.prototype.btnLogin = function btnLogin(){
    const elem = document.createElement('button');
    elem.classList.add("wc-button");
    elem.classList.add("wc-button--secondary");
    elem.type = 'button';
    elem.value = 'Login';
    elem.innerHTML = '<span class="label">Login</span>';
    elem.addEventListener('click', function(){this.instrument('login');nx.login.login_lightbox();}.bind(this));
    return elem;
}
SaveCartReminder.prototype.header = function header(numItems, wordItems){
    const elem = document.createElement('h2');
    elem.classList.add("cart-reminder__header");
    elem.innerHTML = '<span class="cart-reminder__icon"></span>You have ' + numItems + ' ' + wordItems + ' in your cart';
    return elem;
}
SaveCartReminder.prototype.pRegister = function pRegister(){
    const elem = document.createElement('p');
    elem.classList.add("cart-reminder__register");
    elem.innerHTML = 'If you are a new user <a class="cart-reminder__link"href="/main-my-reg" onclick="doAdobeLink(\'Cart reminder:register\');">Register here</a>';
    return elem;
}
SaveCartReminder.prototype.pFooter = function pFooter(){
    const elem = document.createElement('p');
    elem.classList.add("cart-reminder__footer");
    elem.innerHTML = 'Need help placing an order? <a class="cart-reminder__link"href="main-support-contactus" onclick="doAdobeLink(\'Cart reminder:contact\');">Contact us</a>';
    return elem;
}
SaveCartReminder.prototype.pCheckbox = function pCheckbox(){
    const elem = document.createElement('p');
    const cbox = document.createElement('input');
    const label = document.createElement('label');
    this.cbox = cbox;
    cbox.type = 'checkbox';
    cbox.id = 'SaveCartReminderCB';
    elem.insertBefore(cbox, null);
    label.classList.add("cart-reminder__label");
    label.innerHTML = "Do not show this message again";
    label.HTMLFor = 'SaveCartReminderCB';
    elem.insertBefore(label, null);
    return elem;
}
SaveCartReminder.prototype.instrument = function doTag(action){
    const eventMap = {
        'shown': 'event107',
        'dismissed': 'event108',
        'dismissed permenently': 'event109',
        'login': 'event110',
        'save': 'event110'
    }
    if(window._mfq) {
        window._mfq.push(['tag', 'CartRemnder:' + action]);
    }
    s.linkTrackVars = "eVar74,prop74,events";
    s.linkTrackEvents = eventMap[action];
    s.eVar74 = s.prop74 = action;
    s.events = eventMap[action];
    s.tl(true, 'o', 'cart save reminder');
}
SaveCartReminder.prototype.afterClose = function afterClose(){
    if(this.cbox.checked){
        localStorage.setItem('noCartReminder',1);
        this.closeAction = 'dismissed permenently';
    }
    this.dlg=null;
    document.title = this.storedTitle;
    this.instrument(this.closeAction);
}
SaveCartReminder.prototype.remind = function remind(){
    if(this.dlg){return false;}
    Dialogs.load();
    const items = readDatalayer(['tracking','cart','items']);
    if(!items || items.length < 1){ return false;}
    const numItems = items.length;
    const pluralItems = numItems > 1 ? 's' : '';
    document.title = '(' + numItems + ') Item' + pluralItems + ' in cart | Netxpress';
    const content = this.divContent();
    const buttonBar = this.buttonBar();

    content.insertBefore(this.header(numItems, 'item' + pluralItems), null);
    content.insertBefore(buttonBar, null);
    if(readDatalayer(['global','authenticated'])) {
        buttonBar.insertBefore(this.dSavedMessage(), null);
    } else {
        buttonBar.classList.add('cart-reminder__buttons--horizontal');
        buttonBar.insertBefore(this.btnLogin(), null);
        content.insertBefore(this.pRegister(), null);
    }
    buttonBar.insertBefore(this.btnOk(), null);
    content.insertBefore(this.pFooter(), null);
    content.insertBefore(this.pCheckbox(), null);

    this.dlg = new Dialog({
        title: '',
        content: content,
        afterClose: this.afterClose.bind(this)
    });
    this.dlg.open();
    this.instrument('shown');
    return true;
}

export {SaveCartReminder};
