// pikaday calendar functions
const calendarToString = date => {
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  var month =
      date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1;
  var year = date.getFullYear();
  return day + '/' + month + '/' + year;
}

const calendarParse = dateString => {
  var parts = dateString.split('/');
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1] - 1, 10);
  var year = parseInt(parts[2], 10);
  return new Date(year, month, day);
}

export { calendarToString, calendarParse };
