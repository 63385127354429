var listsLoader = function listsLoader() {
  this.initialize.apply(this, arguments);
};

listsLoader.prototype.initialize = function initialize() {
  this.hearts = [];
  this.initializeHearts();
};
listsLoader.prototype.initializeHearts = function initializeHearts(selector) {
  selector = selector || '';
  this.hearts = document.querySelectorAll(
    'button.shopping_list_link, .view_current_main button[title="Add to list"]'
  );
};
listsLoader.prototype.getHeartProdcode = function getHeartProdcode(heart) {
  return heart.parentNode.getAttribute('data-prodcode');
};
listsLoader.prototype.addToList = function addToList(listId, prodcode) {
  var obj = this;
  var uri = '/_product_lists';
  var payload;
  if (listId == 'favourites') {
    payload = `add_to_list=${encodeURI(prodcode)}&list=Favourites`;
  } else {
    payload =
      `add_to_list=${encodeURI(prodcode)}&listid=${encodeURI(listId)}`;
  }
  this.dialog = this.initDialog();
  obj.postNx(
    uri,
    payload,
    this.successfulAdd.bind(this),
    this.failed.bind(this),
    200
  );
};
listsLoader.prototype.createList = function createList(
  listNameField,
  prodcode
) {
  var obj = this;
  var listName = listNameField.value;
  if (listName == '') {
    return false;
  }
  var uri = '/_product_lists';
  var payload =
    'add_to_list=' + encodeURI(prodcode) + '&list=' + encodeURI(listName);
  this.dialog = this.initDialog();
  obj.postNx(
    uri,
    payload,
    this.successfulAdd.bind(this),
    this.failed.bind(this),
    200
  );
  return true;
};
listsLoader.prototype.successfulAdd = function successfulAdd(uri, response) {
  var obj = this;
  if (this.dialog) {
    this.updateDialog(response, 'success');
  }
  //close all list boxes
  document
    .querySelectorAll('div.shopping_list_select.visible')
    .forEach(elem => {
      elem.classList.remove('visible');
    });
  // color the heart
  document
    .querySelectorAll('.shopping_list_container')
    .forEach(function (item) {
      if (item.getAttribute('data-prodcode') == response.prodcode) {
        item.querySelectorAll('button.shopping_list_link').forEach(button => {
          button.classList.add('listed');
        });
      }
    });

  // update all the list boxes
  var popup, selector;
  if (response.action == 'created') {
    this.hearts.forEach(function (heart) {
      popup = heart.nextSibling;
      var thisprodcode = obj.getHeartProdcode(heart);
      // add a button label with heart icon
      var span_label = document.createElement('span');
      span_label.classList.add('label');
      span_label.innerHTML = response.list;
      var i = document.createElement('i');
      i.classList.add('fa-solid');
      i.classList.add('fa-heart');
      span_label.appendChild(i);
      // create a button
      var button = document.createElement('button');
      button.classList.add('button');
      button.classList.add('wc-button');
      button.classList.add('wc-button--secondary');
      button.type = 'button';
      button.value = response.listid;
      if (response.prodcode == thisprodcode) {
        button.classList.add('ticked');
      }
      button.innerHTML = span_label.outerHTML;
      button.onclick = obj.addToList.bind(obj, response.listid, thisprodcode);
      // add the button inside div my-saved-list
      popup.querySelectorAll('div.my-saved-list')[0].appendChild(button);
    });
  } else if (response.action == 'added') {
    document.querySelectorAll('.shopping_list_container').forEach(item => {
      if (item.getAttribute('data-prodcode') == response.prodcode) {
        item.querySelectorAll('.add-to-list-popup button').forEach(button => {
          let curBtnList =
            response.list == 'Favourites' ? 'favourites' : response.listid;
          if (button.getAttribute('value') == curBtnList) {
            button.classList.add('ticked');
          }
        });
      }
    });
  }
  this.instrument();
};
listsLoader.prototype.failed = function failed(response, dialog) {
  this.updateDialog(response, 'error', dialog);
  document
    .querySelectorAll('div.shopping_list_select.visible')
    .forEach(elem => {
      elem.className.remove('visible');
    });
};
listsLoader.prototype.openListsDialog = function openListsDialog(button) {
  var content = button.nextSibling
    .querySelectorAll('.shopping_list_select')[0]
    .cloneNode(true);
  content.classList.add('visible');
  content.classList.add('list-in-dialog');
  var prodcode = button
    .closest('.shopping_list_container')
    .getAttribute('data-prodcode');
  window.listLoader.ajaxifyDialog.call(window.listLoader, content, prodcode); // eslint-disable-line no-useless-call
  window.listLoader.dialog = window.listLoader.initDialog(content);
};
listsLoader.prototype.initDialog = function initDialog(content) {
  var spinner =
    '<p style="text-align:center"><i class = "fa fa-spinner fa-spin"></i></p>';
  var dlgContent = content || spinner;
  Dialogs.load();
  Dialogs.close();
  var dlg = new Dialog({
    padding: 20,
    margin: 20,
    width: 400,
    innerScrollOnlyY: true,
    content: dlgContent
  });
  dlg.open();
  return dlg;
};
listsLoader.prototype.updateDialog = function updateDialog(response, success) {
  let html;
  if (success == 'success') {
    html = 'The product has been added to ';
    html += response.action == 'added' ? ' your ' : ' a new ';
    html += ' list ';
    html +=
      '<a style="text-decoration: underline;" href="/main-my-lists?listid=';
    html += response.listid + '">' + response.list + '</a>.';
  } else {
    html = 'There was a problem adding to your list. Please refresh the page and try again.';
  }
  html = window.nx.ute.wrapK2Message(html, success);
  html +=
    '<button type="button" value="Continue Shopping" style="float:right;" class="wc-button wc-button--primary" ';
  html += 'onclick="Dialogs.close()" >Continue Shopping</button>';
  html +=
    '<button type="button" value="View List" style="float:right;margin-right:10px;" class="wc-button wc-button--secondary" ';
  html +=
    'onclick="document.location=\'/main-my-lists?listid=' +
    response.listid +
    '\';" >View List</button>';

  this.dialog.opt.content = html;
  this.dialog.setContent();
};
listsLoader.prototype.ajaxifyDialog = function ajaxifyDialog(
  dialogContents,
  prodcode
) {
  var obj = this;
  var newField = dialogContents.querySelectorAll(
    'input[name^=new_list_name],input[name=k2c_additeminline_new_list_name]'
  )[0];
  if (prodcode) {
    dialogContents
      .querySelectorAll('button[type=submit],button[type=button]')
      .forEach(function (button) {
        button.type = 'button';
        /* If button name does not start with "create new list" */
        if (!button.name || button.name.indexOf('create_new_list') != 0) {
          button.onclick = obj.addToList.bind(obj, button.value, prodcode);
        }
      });
    dialogContents
      .querySelectorAll('input[name^=create_new_list],button[name^=create_new_list]')
      .forEach(function (button) {
        button.type = 'button';
        button.onclick = obj.createList.bind(obj, newField, prodcode);
      });
  }
};
listsLoader.prototype.instrument = function instrument() {
  // evar28 add to list type
  // prop31 add to list type
  // event12 add to list
  var obj = this;
  var dlEvent = obj.readDatalayer(['event']);
  if (!dlEvent) {
    return false;
  }
  var feature = '';
  for (var e in dlEvent) {
    // get the type from the datalayer
    feature = dlEvent[e].feature ? dlEvent[e].feature : feature;
  }
  window.s.linkTrackVars = 'events,eVar28,prop31';
  window.s.eVar28 = feature;
  window.s.prop31 = feature;
  window.s.linkTrackEvents = 'event12';
  window.s.events = 'event12';
  window.s.tl(true, 'o', 'Add to list');
  return true;
};
listsLoader.prototype.postNx = function postNx(
  uri,
  payload,
  successCB,
  failureCB,
  successStatus
) {
  successStatus = successStatus || 201;
  let request = fetch(uri, {
    body: payload,
    headers: {
      'X-Post-ID': nxDatalayer.global.post_id,
      'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Accept: 'application/json',
      'X-Requested-Wide': 'XMLHttpRequest'
    },
    method: 'POST'
  })
    .then(res => {
      if (res.status == successStatus) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(transport => {
      let res = {};
      res.responseJSON = transport;
      if (res.responseJSON !== null) {
        successCB(uri, res.responseJSON);
      } else {
        failureCB(uri);
      }
    })
    .catch(err => {
      failureCB(uri);
    });
};
listsLoader.prototype.readDatalayer = function readDatalayer(dlName) {
  // Accepts an array and returns that argument from the
  // nxDatalayer unless it is falsey, when it returns false
  // If the node doesn't exist it return false;
  // e.g. readDatalayer(['global', 'streams', 'control_chk'])
  if (typeof nxDatalayer == 'undefined') {
    return false;
  }
  var node = nxDatalayer;
  for (var i = 0; i < dlName.length; i++) {
    if (node[dlName[i]]) {
      node = node[dlName[i]];
    } else {
      return false;
    }
  }
  return node;
};
export default listsLoader;
