/* global $j, nx */

function SubmitPcPrompt(atc_form, prodcode) {
    let pc = document.querySelector('#pc_postcode').value;

    // No value entered
     if(pc == '') {
        document.querySelector('#postcode_error').innerHTML = "Please enter a postcode";
        return false;
    } else if (pc && !pc.match(/^\d{4}$/)) {
        // Check postcode is 4 numbers
        document.querySelector('#postcode_error').innerHTML = "Please insert a valid postcode.";
        return false;
    }

    var request = $j.ajax({
        method: "POST",
        url: "/main-ajax-pcprompt",
        data: { "postcode": pc }
    });
    request.done(function() {
        nxDatalayer.global.postcode = pc;

        // Submit "Add to Cart" form
        if (atc_form) {
            // Display spinner
            window.nx.open_lightbox.close();
            window.nx.open_lightbox = null;
            const arr = $j(atc_form).find('[type="submit"][value="Add"],[type="submit"][value="Add to Cart"]');
            const submit_btn = arr.first();
            if (submit_btn.click) {
                submit_btn.click();
            }
        } else if (prodcode) {
            // display quickview
            nx.products.quickView(prodcode);
        }
        // No form to submit, accessed from deliver to link
        else {
            if (etdp) {
                etdp.postcodeChanged(pc);
            }
            document.querySelector('#dt_pc').innerHTML = pc;
            Dialogs.close();
        }
    });       
    request.fail(function( jqXHR, textStatus ) {
        document.querySelector('#postcode_error').innerHTML = "An error occurred, please try again.";
    });
    return true;
}

function PostcodePrompt(intercept_cart) {
    // Search for Add and Add to Cart buttons, set onclick handler
    // this piece of code will be removed later when ajax add to cart is turned on for everyone
    if (intercept_cart == true) {
        $j('[type="submit"][value="Add"],[type="submit"][value="Add to Cart"]').each(function () {
            this.addEventListener('click', function (evt) {
                DisplayPcPrompt(evt);
            });
        });
    }

    // Check if user wants to change postcode.
    if (document.querySelector('#dt_pc')) {
        document.querySelector('#dt_pc').addEventListener('click', function () {
            pc_lightbox('');
        });
    }
}

function change_postcode(evt) {
    pc_lightbox('');
    evt.stopPropagation();
    if (evt.preventDefault) evt.preventDefault();
}

function DisplayPcPrompt(ev) {
    // if something has already set the postcode, do nothing
    if (nxDatalayer.global.postcode) {
        return false;
    }

    // this method could be called twice
    // exit immediately if it happens
    if (Dialogs._open) {
        return true;
    }

    // Get event element's associated form
    var atc_form = ev.currentTarget.closest('form');

    // if they clicked to add multiples to cart we should remember this.
    const qty = ev.currentTarget.getAttribute('data-qty');
    const fld = atc_form.querySelector('.qty_text');
    if(fld && qty) {
        fld.value = qty;
    }

    // Create lightbox
    pc_lightbox(atc_form);

    // Stop 'Add to Cart' submit event.
    ev.stopPropagation();
    ev.preventDefault();
    return true;
}

function pc_lightbox(atc_form, prodcode) {
    Dialogs.load();
    new Dialog({
        autoOpen: true,
        width: 550,
        height: 150,
        ajax: {
            url: '/main-ajax-pcprompt',
            options: {
                onComplete: function (t) {
                    // Style lightbox
                    $j('#pc_postcode').focus();

                    // Observe postcode prompt submit
                    document.querySelector('#pc_submit').addEventListener('click', function () {
                        SubmitPcPrompt(atc_form, prodcode);
                    });
                    document.querySelector('#pc_postcode').addEventListener('keydown', function (evt) {
                        if (evt.key == "Enter") {
                            SubmitPcPrompt(atc_form, prodcode);
                        }
                    });
                }
            }
        },
        afterOpen: function (d) {
            window.nx.open_lightbox = d;
        }
    });
}


export {
    DisplayPcPrompt,
    pc_lightbox,
    change_postcode,
    PostcodePrompt
};
