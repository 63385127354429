import { addClassName, getElementByID, removeClassName } from '../ute/utils';

const searchClose = () => {
  const overlay = getElementByID('search_overlay');
  removeClassName(document.body, 'search_active');
  removeClassName(overlay, 'active_overlay');
  addClassName(overlay, 'inactive');
};

export default searchClose;
