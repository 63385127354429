/* global $j, nx */
import { doAdobeEvent } from "../ute/utils";
// super class for carousels
var BasicCarousel = function BasicCarousel(){
};
BasicCarousel.prototype.slideNext = function slideNext(evt, method){
  if(this.curPos + 1 >= this.numPanes) return this.resetScroll();
  this.slideTo(this.curPos + 1);
  if(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  this.recordInteraction(method);
  return true;
};
BasicCarousel.prototype.slidePrev = function slidePrev(evt, method){
  if(this.curPos - 1 < 0) return this.resetScroll();
  this.slideTo(this.curPos - 1);
  if(evt) { 
    evt.stopPropagation();
    evt.preventDefault();
  }
  this.recordInteraction(method);
  return true;
};
BasicCarousel.prototype.slideTo = function slideTo(num){
  this.inner.style.transition = 'margin-left 0.2s';
  if(typeof this.checkImg == 'function') {this.checkImg(num);}
  const buffer = this.buffer || 0;
  let gap = this.gap || 0;
  if(num == 0) gap = 0;
  this.inner.style.marginLeft = '-' + ((num * this.PANE_WIDTH) + buffer + gap) + 'px';
  this.curPos = num;
  this.updateNav(num);
};
BasicCarousel.prototype.resetScroll = function resetScroll() {
  this.inner.style.transition = 'margin-left 0.2s';
  var buffer = this.buffer || 0; // nb no gap at zero
  this.inner.style.marginLeft = '-' + ((this.curPos * this.PANE_WIDTH) + buffer) + 'px';
  return false;
};
BasicCarousel.prototype.animateScroll = function animateScroll(distance) {
  this.inner.style.transition = '';
  this.inner.style.marginLeft = ((-1 * (this.curPos * this.PANE_WIDTH) - distance)) + 'px';
  return false;
};
BasicCarousel.prototype.recordInteraction = function recordInteraction(method) {
  if('swipe' && !localStorage.getItem(this.localStorageName, '1')){
    localStorage.setItem(this.localStorageName, '1');
  }
  doAdobeEvent(method == 'swipe' ? this.swipeEvent : this.clickEvent, this.adobeDescription);
};
BasicCarousel.prototype.updateNav = function updateNav(num) {
  if(this.dotsClass){
    for(var i = 0; i < this.dotsArr.length; i++){
      if(i != num){
        this.dotsArr[i].classList.add(this.dotsSelectedClass);
      }else{
        this.dotsArr[i].classList.remove(this.dotsSelectedClass);
      }
    }
  }
  this.prevButton.style.visibility = (num == 0) ? 'hidden' : 'visible';
  this.nextButton.style.visibility = (num >= (this.numPanes - 1)) ? 'hidden' : 'visible';
}
BasicCarousel.prototype.setupNav = function setupNav(){
  var dots, dot, i, obj;

  this.prevButton = document.createElement('a');
  this.prevButton.className = this.navLeftClass;
  this.prevButton.title = 'Previous';
  this.prevButton.name = 'Previous';
  this.prevButton.ariaLabel = 'Previous';
  this.prevButton.role = 'button';
  this.prevButton.addEventListener('click',this.slidePrev.bind(this));  
  this.nextButton = document.createElement('a');
  this.nextButton.className = this.navRightClass;
  this.nextButton.title = 'Next';
  this.nextButton.name = 'Next';
  this.nextButton.ariaLabel = 'Next';
  this.nextButton.role = 'button';
  this.nextButton.addEventListener('click',this.slideNext.bind(this));  
  this.wrapper.appendChild(this.prevButton);
  this.wrapper.appendChild(this.nextButton);
  if(this.dotsClass) {
    dots = document.createElement('ul');
    dots.className = this.dotsContainerClass;
    obj = this;
    for (i = 0; i < this.numPanes; i++) {
      //add a dot
      dot = document.createElement('li');
      dot.className = this.dotsClass;
      dot.title = 'Image ' + (i + 1);
      if(i==0){dot.classList.add(this.dotsSelectedClass)}
      dot.addEventListener('click',(function(n){return function(){obj.slideTo(n)}})(i)); //closure
      this.dotsArr.push(dot);
      dots.appendChild(dot);
    }
    this.wrapper.appendChild(dots);
  }
  this.updateNav(this.curPos);
}

export {BasicCarousel};
