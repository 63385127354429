/********************************
 * helpers
 *******************************/
/* eslint-disable consistent-return */
const getLayer = layerName => {
  // standard browsers.
  if (document.getElementById) {
    return document.getElementById(layerName);
  }
  // old MSIE browsers.
  if (document.all) {
    return document.all[layerName];
  }
  // NN4
  if (document.layers) {
    return document.layers[layerName];
  }
};
const block = 'block';
const none = 'none';
const empty = '';
/********************************
 * exports
 *******************************/
//TODO: This could be replaced with
export const toggleLayerByDom = element => {
  element.style.display =
    element.display === empty || element.style.display == block ? none : block;
};

export const toggleLayerByName = layerName => {
  toggleLayerByDom(getLayer(layerName));
};
