/* global Genesys, embeddedservice_bootstrap */

export default source => {
  if(typeof Genesys !== "undefined") {
    /* Genesys chat */
    Genesys("subscribe", "Messenger.ready", function() {
      window.live_chat.source = source;
      Genesys("command", "Messenger.open", {});
    });
  } else if(typeof embeddedservice_bootstrap !== "undefined") {
    /* Salesforce live chat */
    if( embeddedservice_bootstrap.utilAPI === undefined ) {
      /* May not yet be ready, trigger when ready */
      window.addEventListener("onEmbeddedMessagingReady", () => {
        window.live_chat.source = source;
        embeddedservice_bootstrap.utilAPI.launchChat();
      });
    } else {
      window.live_chat.source = source;
      embeddedservice_bootstrap.utilAPI.launchChat();
    }
  }
}
