import { parseAjaxText } from '../ute/utils';
/********************************
 * helpers
 *******************************/
const cartContentID = 'cart_content';
const displayBlock = 'block';
const displayNone = 'none';
const ajaxUrl = '/main-ajax-cartitems';
const cartItemsID = 'cart_items';
const dashboardID = 'dashboard';
const timeoutSpan = 500;
const mouseOverEvent = 'mouseover';
const mouseOutEvent = 'mouseout';

const updateCartItems = text => {
  const cartItems = document.getElementById(cartItemsID);
  if (!cartItems) return;
  cartItems.innerHTML = text;
};
const loadItems = (nItems, isAjaxLoaded, setIsAjaxLoaded) => {
  // Display Ajax container
  document.getElementById(cartContentID).style.display = displayBlock;

  if (isAjaxLoaded) {
    return;
  }
  setIsAjaxLoaded(true);

  // Only fire Ajax if items have been ordered.
  if (nItems) {
    fetch(ajaxUrl).then(parseAjaxText).then(updateCartItems);
  }
};

const hideCartItems = setDashTo => () => {
  const to = setTimeout(() => {
    const cartContent = document.getElementById(cartContentID);
    if (cartContent?.style) cartContent.style.display = displayNone;
  }, timeoutSpan);
  setDashTo(to);
};
/********************************
 * Cart Items - Class
 *******************************/
class CartItems {
  constructor(nItems) {
    /** props **/
    this.dashTo = null;
    this.isLoaded = false;
    this.nItems = nItems;
    /** bind methods **/
    this.setDashTo = this.setDashTo.bind(this);
    this.setIsLoaded = this.setIsLoaded.bind(this);
    this.mouseOver = this.mouseOver.bind(this);
    /** event listeners **/
    const dashboard = document.getElementById(dashboardID);
    if (dashboard) {
      // Display top 5 products
      dashboard.addEventListener(mouseOverEvent, this.mouseOver);
      // Hide top 5 products
      dashboard.addEventListener(mouseOutEvent, hideCartItems(this.setDashTo));
    }
  }

  mouseOver() {
    clearTimeout(this.dashTo);
    loadItems(this.nItems, this.isLoaded, this.setIsLoaded);
  }

  setDashTo(to) {
    this.dashTo = to;
  }

  setIsLoaded(isLoaded) {
    this.isLoaded = isLoaded;
  }
}

export default CartItems;
/********************************
 * export for testing
 *******************************/
export {
  cartContentID,
  dashboardID,
  cartItemsID,
  mouseOverEvent,
  mouseOutEvent,
  displayNone,
  displayBlock,
  timeoutSpan,
  loadItems,
  hideCartItems,
  updateCartItems
};
