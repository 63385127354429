/* global nx, s */
const size = 336; // width of the drawer
let content; // holds the drawer's contents
let current; // holds the uri to be applied
const modeMap = {
  'main-catalogue-search': 'search',
  'main-catalogue-browse': 'browse',
  'main-catalogue-xpressforms': 'xpressforms',
  'main-catalogue-similar': 'similar',
  'main-catalogue-previous': 'previous',
  'main-catalogue-inkandtonerfinder': 'inkandtoner' 
};
const rx = /\?(.*)/;
const spinnerHtml = `<div class="plp_filter__spinner">
  <i class="fa fa-spinner rotate" aria-hidden="true">
</div>`;

export const filterAdobe = (ev, evName) => {
  s.linkTrackVars='prop25,prop26,prop27,prop36,prop58,eVar25,eVar26,eVar27,eVar58,events';
  s.linkTrackEvents=ev;
  s.events = ev;
  s.tl(true,'o', evName);
}

export const hijackLinks = () => {
  const links = content.querySelectorAll('a');
  links.forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();
      content.innerHTML = spinnerHtml;
      const root = window.location.pathname.split('/')[1];
      const mode = modeMap[root] || 'search';
      const ene = link.getAttribute('data-ene');
      const full = ene.split('#');
      const path = full[0];
      const frag = full[1] || '';
      const sep = path.indexOf('?') > -1 ? '&' : '?';
      const query = `${path}${sep}mode=${mode}#${frag}`;
      const src = `/main-ajax-refine${query}`;
      // remenber the url in case the user wants to apply it
      current = link.getAttribute('href');
      fetch(src)
        .then(response => response.text())
        .then(data => {
          content.innerHTML = data;
          hijackLinks(content);
        });
      });
  });
}

export const showFilters = () => {
  const original = document.querySelector('.plp_filter__content');
  content = original.firstChild.cloneNode(true);
  hijackLinks(content);
  filterAdobe('event156', 'Show filters clicked');
  nx.products.filterDrawer = new nx.drawer.nxDrawer({
    entry:'left',
    content,
    timeout:'modal',
    size,
    showClose:false
  });
}

export const applyFilters = () => {
  filterAdobe('event155', 'Apply filters Clicked');
  window.location = current || window.location;
}

export const expandFilter = el => {
  const filter = el.parentNode;
  nx.ute.toggleClassName(filter,'collapsed');
  nx.ute.toggleClassName(filter,'expanded');
}
