import { doAdobeLink } from "../ute/utils";
  
const furnitureFreight = () => {
  var url = window.location.pathname;
  var search_index = url.indexOf('variantproduct');
  var postcode = document.getElementById('postcode').value;
  var prodcode, quantity, match_num, matches;
  var renum = new RegExp('^[0-9]+$');
  var re = new RegExp(
    '^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$'
  );

  if (search_index == -1) {
    //If it is a non-variant product
    prodcode = document.getElementById('prodcode').value;
  } else {
    //If it is a variant product
    prodcode = document.getElementById('result').value;
  }
  quantity = document.getElementById('quantity').value.trim();

  match_num = renum.exec(quantity);

  if (quantity == 0 || match_num == null || matches == '') {
    if (!document.querySelector('#freight_value').classList.contains('error')) {
      document.querySelector('#freight_value').classList.add('error');
    }
    document.querySelector('#freight_value').innerHTML = 'Invalid quantity.';
    doAdobeLink('pdp : furniture freight');
    return;
  }
  matches = re.exec(postcode);

  if (matches == null || matches == '') {
    if (!document.querySelector('#freight_value').classList.contains('error')) {
      document.querySelector('#freight_value').classList.add('error');
    }
    document.querySelector('#freight_value').innerHTML = 'Invalid postcode';
    document.getElementById('postcode').focus();
    doAdobeLink('pdp : furniture freight');
    return;
  }

  document.querySelector('#freight_value').innerHTML =
    '<img src="/media/images/spin.gif"/>';
  let furnitureUrl =
    '/main-ajax-freight' +
    '?postcode=' +
    postcode +
    '&prodcode=' +
    prodcode +
    '&quantity=' +
    quantity;
  fetch(furnitureUrl, {
    method: 'GET'
  })
    .then(function (res) {
      if (res.ok) {
        return res.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then(data => {
      var r = data;
      var item;
      if (quantity > 1) {
        item = 'items';
      } else {
        item = 'item';
      }
      document.querySelector('#freight_value').className = 'freight_msg';
      document.querySelector('#freight_value').innerHTML =
        r.freight +
        ' Delivery (' +
        quantity +
        ' ' +
        item +
        ' to ' +
        postcode +
        ')';
    })
    .catch(function (e) {
      if (
        !document.querySelector('#freight_value').classList.contains('error')
      ) {
        document.querySelector('#freight_value').classList.add('error');
      }
      document.querySelector('#freight_value').innerHTML =
        'An error occurred, please try again.';
    });

  // if etd is loaded, we want to update the etd postcode
  if (etdp) {
    etdp.freightPostcodeChanged(postcode);
  }
  doAdobeLink('pdp : furniture freight');
};

/* Furniture Freight Request */
const onlySubmitFurnitureFreight = () => {
  if (window.event.keyCode == 13) {
    window.event.preventDefault();
    furnitureFreight();
    return false;
  }
  return true;
};

export { furnitureFreight, onlySubmitFurnitureFreight };
