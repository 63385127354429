import { updateParam } from '../ute/utils';
import { makePost } from '../ute/utils';
import { wrapK2Message } from '../ute/k2message';

const loginSomewhere = (evt, lastUri) => {
  if (
    !(
      typeof nxDatalayer == 'object' &&
      nxDatalayer.global &&
      nxDatalayer.global.profiles.ajaxLogin
    )
  ) {
    return false;
  }

  var i,
    uri = '',
    payload,
    form,
    button,
    errorDiv,
    authenticators = [
      'https://' + document.location.host + '/_authenticate'
    ];

  function displayError(endpointUri, response) {
    if (response?.url) {
      // Redirect to response.url
      document.location = response.url;
    }
    if (document.querySelectorAll('.login_error').length == 0) {
      if (response?.message) {
        errorDiv.innerHTML = wrapK2Message(response.message, 'error');
      }
      const target = document.getElementById('serverError');
      if (target) {
        target.innerHTML = wrapK2Message('Incorrect username/email or password. Please try again.', 'error');
      } else {
        errorDiv = document.createElement('div');
        errorDiv.className = 'login_error';
        errorDiv.innerHTML = wrapK2Message('Incorrect username/email or password. Please try again.', 'error');
        document.querySelectorAll('.login-container').forEach(function (elem) {
          elem.insertBefore(errorDiv, elem.firstChild);
        });
      }

      if(typeof window.nx.login?.login_dialog?.setDimensions == 'function') {
        window.nx.login.login_dialog.setDimensions();
      }
    }
    button.style.display = 'block';
    document.querySelector('#login_spinner').style.display = 'none';
  }

  function loginSuccess(endpointUri, response) {
    var newURI;
    window.s.linkTrackVars = 'events,eVar47';
    window.s.linkTrackEvents = 'event39';
    window.s.events = 'event39';
    window.s.eVar47 = endpointUri;
    window.s.tl(true, 'o', 'Ajax Login');

    if (!(response.url.indexOf(document.location.host) > -1)) {
      //different domain
      document.location = response.url;
    } else {
      newURI = form.remember_me.checked
        ? updateParam(document.location.href, 'rememberme', '+14d')
        : document.location.href;
      document.location = updateParam(newURI, 'attach', response.attach);
    }
  }

  evt = evt || window.event;
  const el = evt.currentTarget;
  button = el.closest('button');

  form = button.form;
  evt.stopPropagation();
  evt.preventDefault();

  //validate
  if (!(form.user.value && form.pass.value)) {
    displayError();
    return false;
  }
  button.style.display = 'none';
  document.querySelector('#login_spinner').style.display = 'block';
  // find the next URI
  if (lastUri == undefined) {
    uri = authenticators[0];
  } else {
    for (i = 0; i < authenticators.length - 1; i++) {
      if (authenticators[i] == lastUri) {
        uri = authenticators[i + 1];
        break;
      }
    }
  }

  //try a login
  payload = 'login=' + encodeURIComponent(form.user.value);
  payload += '&password=' + encodeURIComponent(form.pass.value);
  payload += '&remember_me=' + (form.remember_me.checked ? '1' : '');
  makePost(uri, payload, loginSuccess, displayError);

  return false;
}

export { loginSomewhere };
