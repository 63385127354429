// TODO Manual testing - @Kyle
const insertImage = frame => {
  let err, elem;
  try {
    // http://www.w3schools.com/jsref/prop_frame_contentwindow.asp
    const doc = frame.contentWindow || frame.contentDocument;
    const docOfDoc = doc.document;
    const fDoc = docOfDoc || doc;

    elem = fDoc.getElementById('image');
    if (!elem) {
      err = fDoc.body.innerHTML;
    }
  } catch (e) {
    err =
      '<h1>No Response From Server</h1><p>Try again, and if the problem persists, contact the NetXpress helpdesk.</p>';
  }

  if (err) {
    new Dialog({
      title: ' Failed (Unexpected Error)',
      content: '<div class="errbox">' + err + '</div>'
    }).open();
  } else {
    const src = elem.src;
    if (src) {
      tinymce.activeEditor.insertContent('<img src="' + src + '" />');
    }
  }
};

export default insertImage;
