import { parseAjaxText } from '../../ute/utils';
/********************************
 * helpers
 *******************************/
const imgSrc = '/media/images/new/ajax-mini.gif';
const getContent = id =>
  `<span id="${id}"><img src=${imgSrc} /> checking...</span>`;
const getUrl = selectProdCode => `/main-ajax-stock?prodcode=${selectProdCode}`;
/********************************
 * stockUpdate()
 *******************************/
export const stockUpdate = (eltID, selectProdCode) => {
  const eltDom = document.getElementById(eltID);
  eltDom.replace(getContent(eltID));

  fetch(getUrl(selectProdCode))
    .then(parseAjaxText)
    .then(text => {
      eltDom.innerHTML = text;
    });
};
